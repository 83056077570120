import { useState } from "react";
import { useEffectOnce } from "usehooks-ts";

const useIsClient = () => {
  const [isClient, setClient] = useState(false);
  const key = isClient ? "client" : "server";

  useEffectOnce(() => {
    setClient(true);
  });

  return { isClient, key };
};

export { useIsClient };
