import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import classNames from "clsx";

const ENV_COLOR_MAP: Record<string, string> = {
  local: "#00DD44",
  netlify: "#99EE66",
  dev: "#FFCC00",
};

const BuildInfo: React.FC = () => {
  const envName = process.env.GATSBY_ENV;
  const envColor = (envName ? ENV_COLOR_MAP[envName] : "#FFCC00") || "#FFCC00";

  const data = useStaticQuery(graphql`
    query {
      gitCommit {
        hash
      }
      currentBuildDate {
        currentDate
      }
    }
  `);

  const detailPopover = (
    <Popover
      id="popover-basic"
      style={
        {
          "--bs-popover-max-width": 400,
          "--bs-popover-bg": envColor,
        } as React.CSSProperties
      }
    >
      <Popover.Body>
        <dl className={"m-0 p-0"}>
          <dt>Build ID</dt>
          <dd className={"font-monospace"}>
            {data.gitCommit.hash.substring(0, 8)}
          </dd>
          <dt>Build Date</dt>
          <dd className={"font-monospace"}>
            {data.currentBuildDate.currentDate}
          </dd>
        </dl>
      </Popover.Body>
    </Popover>
  );

  return (
    <div
      id="build_info"
      className={classNames(envName === "production" ? "d-none" : null)}
    >
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="bottom"
        overlay={detailPopover}
      >
        <div
          style={
            {
              backgroundColor: envColor,
              zIndex: 65535,
              "--bs-badge-color": "black",
            } as React.CSSProperties
          }
          className={classNames(
            "position-absolute top-0 start-50",
            "badge fs-7 font-body px-2 py-1",
            "rounded-bottom fw-600"
          )}
          tabIndex={0}
        >
          {envName}
        </div>
      </OverlayTrigger>
    </div>
  );
};

export { BuildInfo };
