import { useCallback, useEffect, useRef } from "react";

const isBrowser = typeof window !== "undefined";

type ScrollPosition = Record<"x" | "y", number>;

type HandlerProps = Record<"previous" | "current", ScrollPosition>;

interface UseScrollPosition {
  handler?: (handlerProps: HandlerProps) => void;
  debounceMs?: number;
}

const getScrollPosition = (): ScrollPosition => {
  return !isBrowser ? { x: 0, y: 0 } : { x: window.scrollX, y: window.scrollY };
};

const useScrollPosition = ({
  handler,
  debounceMs: debounceMs,
}: UseScrollPosition): ScrollPosition | undefined => {
  const position = useRef(getScrollPosition());

  const throttleTimeout = useRef<any>(null);

  const callBack = useCallback(() => {
    const currPos = getScrollPosition();
    if (handler) {
      handler({ previous: position.current, current: currPos });
    }
    position.current = currPos;
    throttleTimeout.current = null;
  }, [handler]);

  useEffect(() => {
    const handleScroll = () => {
      if (debounceMs) {
        if (throttleTimeout.current === null) {
          throttleTimeout.current = setTimeout(callBack, debounceMs);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [callBack, debounceMs]);

  if (!handler) {
    return position.current;
  }
};

export { useScrollPosition };
export type { ScrollPosition, HandlerProps, UseScrollPosition };
